// 
// user.scss
// Use this to write your custom SCSS
//

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
  }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 6rem;
      line-height: 1.0;
      letter-spacing: -.03em;
  
    }
  }
.display-2 {
    font-size: calc(1.625rem + 10.5vw);
  }
  @media (min-width: 800px) {
    .display-2 {
      font-size: 4rem;
      line-height: 1.0;
      letter-spacing: -.03em;
  
    }
  }

  .highlight{
    display: inline-block;
    margin-top: 1em;
    padding: 0.35em 1em;
    background: #c8d816;
    color: #ffffff;
}